import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { HorizontalLine } from 'components';
import {
  useLandingPageData,
  Header,
  About,
  RunningText,
  Product,
  Boxes,
  Blogs,
  Partner,
  Jordan
} from 'sections/landingPage';

// External Data
import { useKisLandingPageData } from 'boxenStopHooks';

const Home = () => {
  const { seo, product, partner } = useLandingPageData();

  const {
    header: cmsHeader,
    about: cmsAbout,
    product: cmsProduct,
    boxes: cmsBoxes,
    blogs: cmsBlog,
    partner: cmsPartner,
    jordan: cmsJordan
  } = useKisLandingPageData();
  return (
    <>
      <SEO {...seo} />
      <Header data={cmsHeader} />
      <HorizontalLine />
      <About data={cmsAbout} />
      <RunningText />
      <Product staticData={product} data={cmsProduct} />
      <HorizontalLine />
      <Boxes data={cmsBoxes} />
      <HorizontalLine />
      <Blogs data={cmsBlog} />
      <HorizontalLine />
      <Partner staticData={partner} data={cmsPartner} />
      <Jordan data={cmsJordan} />
    </>
  );
};

export default injectIntl(Home);
